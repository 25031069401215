<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="9" class="pt-1">
                    <span class="headline font-weight-medium">Nueva Transferencia</span>
                </v-col>
                <v-col cols="3" class="pt-1">
                    <v-btn dark color="#023145" width="140" class="float-right ml-3" @click="handleStoreTranfer">
                        Transferir
                    </v-btn>
                    <v-btn dark color="red" class="float-right" @click="handleCancelTranfer">
                        Cancelar
                    </v-btn>                    
                </v-col>
            </v-row>

            <v-row class="h-100">
                <v-col cols="8" class="pt-0 pb-0">
                    <template>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <v-card>
                                    <v-card-text>
                                        <template>
                                            <v-row>                                                
                                                <v-col cols="2" class="pr-0">
                                                    <div style="padding-top: 10px;">
                                                        <span class="subtitle-2 float-right" style="font-size: 1rem !important;">
                                                            Origen
                                                        </span>
                                                    </div>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-autocomplete
                                                        ref="origin_id"
                                                        v-model="origin_id"
                                                        :items="storehouses"
                                                        label="Seleccione Origen"
                                                        outlined
                                                        :item-text="customText"
                                                        item-value="id"
                                                        :rules="[() => !!origin_id || 'Es necesario seleccionar almacén de origen']"
                                                        hide-details="auto"
                                                        required
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="2" class="pr-0">
                                                    <div style="padding-top: 10px;">
                                                        <span class="subtitle-2 float-right" style="font-size: 1rem !important;">
                                                            Destino
                                                        </span>
                                                    </div>
                                                </v-col>

                                                <v-col cols="4">
                                                    <v-autocomplete
                                                        ref="destination_id"
                                                        v-model="destination_id"
                                                        :items="storehouses"
                                                        label="Seleccione Destino"
                                                        outlined
                                                        :item-text="customText"
                                                        item-value="id"
                                                        :rules="[() => !!destination_id || 'Es necesario seleccionar almacén destino']"
                                                        hide-details="auto"
                                                        required
                                                    ></v-autocomplete>
                                                </v-col>

                                            </v-row>

                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-card>
                                    <v-card-text>
                                        <template>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-autocomplete
                                                        v-model="value_category"
                                                        :items="categories"
                                                        label="Seleccione Categoria"
                                                        hide-details
                                                        outlined
                                                        :item-text="customText"
                                                        item-value="id"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>

                                                <v-col cols="4">
                                                    <v-text-field v-model="search_product" 
                                                        label="Producto" 
                                                        outlined 
                                                        hide-details
                                                        @keydown="handleInputSearchByEnter">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="4">
                                                    <v-btn @click="handleSearchInventory">
                                                        Mostrar productos
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" class="pt-2 pb-0">
                                <v-card>
                                    <v-data-table
                                        :headers="headers"
                                        :items="inventories"
                                        disable-pagination
                                        hide-default-footer
                                        class="w-table"
                                        height="48vh"
                                        fixed-header
                                    >
                                        <template v-slot:item.description="{item}">
                                            <span>{{ item.product_description }}</span>
                                        </template>

                                        <template v-slot:item.actions="{item}">
                                            <v-btn small 
                                                dark 
                                                color="blue"
                                                class="float-right" 
                                                @click="handleSelectProduct(item)">
                                                Seleccionar
                                            </v-btn>
                                        </template>
                                        
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>

                <v-col cols="4" class="pt-0 pb-0">
                    <v-card class="section-list-products" style="height: 100% !important">
                        <v-card-title class="list-product-selected">
                            Productos seleccionados
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pl-0 pr-0 pt-0 pb-0">
                            <template>
                                <v-list class="pt-0">
                                    <v-list-item v-for="(product, index) in products" :key="product.inventory_id" class="invoice-item text-uppercase">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ product.description }}
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action class="transfer-item-action">
                                            <v-list-item-action-text>
                                                    <span class="label-quantity-transfer">{{ product.sent_quantity }}</span>
                                                    <span @click="handleDeleteItem(index)" class="ml-2 btn-item-delete">
                                                        <v-icon size="15" color="red">fas fa-times</v-icon>
                                                    </span>
                                            </v-list-item-action-text>                                            
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </template>

        <v-dialog
            v-model="dialog_select"
            width="400"
            transition="fab-transition"
        >
            <v-card class="pl-0 pr-0">
                <v-card-title v-if="inventory">
                    <span class="description-selected">
                        {{ inventory.product_description }}
                    </span> 
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pb-0">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    ref="sent_quantity"
                                    v-model="formSelect.sent_quantity"
                                    label="Cantidad"
                                    outlined
                                    type="number"
                                    :rules="[
                                        () => !!formSelect.sent_quantity || 'Es necesario ingresar la cantidad',
                                        () => (!!formSelect.sent_quantity && parseInt(formSelect.sent_quantity) > 0) || 'La cantidad debe ser mayor a cero',
                                        () => (!!formSelect.sent_quantity && parseInt(formSelect.sent_quantity) <= formSelect.current_stock) || 'La cantidad a transferir supera al stock actual'
                                    ]"
                                    hide-details="auto"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-container class="pt-0 pb-0">
                        <v-row>
                            <v-col cols="6">
                                <v-btn dark color="red" block @click="handleCancelSelectProduct">
                                    Cancelar
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn dark color="green" block @click="handleAddProduct">
                                    Aceptar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>                    
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="show_message_products"            
            color="error"
            :timeout="3000"
            :top="'top'"
        >
            {{ message_validation_error }}
            <v-btn
            dark
            text
            @click="show_message_products = false"
            >
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>

import {mapState, mapActions} from 'vuex';
import router from '@/router/index';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import ArrayTools from '../../../helpers/ArrayTools'

export default {
    name: 'NewTranfer',
    components: {
        OfficeLogin
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Logística',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Transferencias',
                    disabled: false,
                    href: '/app/logistica/transferencias',
                }
            ],
            headers: [
                { text: 'PRODUCTO', value: 'description' },
                { text: 'STOCK', value: 'stock' },
                { text: '', value: 'actions', sortable: false }
            ],
            show_message_products: false,
            message_validation_error: null,
            products: [],
            value_company: null,
            sedes: [],
            value_sede: null,
            value_office: null,
            value_category: null,
            search_product: null,
            dialog_select: false,
            inventory: null,            
            category_name: null,
            origin_id: null,
            destination_id: null,
            formSelect: {
                inventory_id: null,
                description: null,
                category: null,
                sent_quantity: null,
                imei_start: null,
                imei_end: null,
                imei_single: null,
                imei_list: null,
                imeis_rank: false,
                current_stock: 0
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'user_companies',
            'user_sedes',
            'user_offices',
            'company_id'
        ]),
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('inventories', [
            'inventories',
        ]),
        ...mapState('transfers', [
            'transfer'
        ]),
        ...mapState('offices', [
            'offices'
        ]),
        ...mapState('storehouses', [
            'storehouses'
        ])
    },
    methods: {
        ...mapActions('categories', [
            'getCategories'
        ]),
        ...mapActions('inventories', [
            'setInventories',
            'clean_data',
            'getInventories',
            'getProductsToTransfer'
        ]),
        ...mapActions('transfers', [
            'store',
            'empty_transfer'
        ]),
        ...mapActions('offices', [
            'getOffices'
        ]),
        ...mapActions('storehouses', [
            'getStorehouses'
        ]),
        customText(item){
            let text = item.name
            
            return text
        },
        customTextImei(item){
            let text = item.imei_1
            
            return text
        },
        handleSelectProduct(item){
            this.clearForm()
            this.inventory = null
            this.inventory = item
            this.formSelect.category = item.category_name;
            this.formSelect.current_stock = this.inventory.stock;
            this.dialog_select = true;            
        },
        handleCancelSelectProduct(){
            this.closeForm()
        },
        closeForm() {
            this.clearForm()
            this.inventory = null
            this.dialog_select = false
        },
        async clearForm() {
            this.formSelect.inventory_id = null
            this.formSelect.category = null
            this.formSelect.description = null
            this.formSelect.sent_quantity = null
            this.formSelect.imei_start = null
            this.formSelect.imei_end = null
            this.formSelect.imei_single = null
            this.formSelect.imei_list = null
            this.formSelect.imeis_rank = false
            this.formSelect.current_stock = 0;
            await this.clearErrorsDialog();
        },
        handleAddProduct() {
            if (!this.existErrorsDialog()) {
                if (parseFloat(this.formSelect.sent_quantity) <= parseFloat(this.formSelect.current_stock)) {
                    this.formSelect.inventory_id = this.inventory.id
                    this.formSelect.description =  this.inventory.product_description

                    let product_item = Object.assign({}, this.formSelect);
                    this.products.push(product_item)

                    this.closeForm()
                }
            }
        },
        clearData() {
            this.origin_id = null;
            this.destination_id = null;
            this.value_company = null
            this.value_sede = null
            this.value_office = null
            this.value_category = null
            this.search_product = null
            this.inventory = null
            this.products = []
            this.clearForm();
            this.clearErrors();
        },
        handleCancelTranfer() {
            this.clearData();
            this.empty_transfer();
            this.clean_data();
            router.push('/app/logistica/transferencias');
        },
        async handleStoreTranfer() {
            if (!this.existErrors()) {
                if (this.hasProducts()) {
                    let transfer = {
                        company_id: this.company_id,
                        origin_id: this.origin_id,
                        destination_id: this.destination_id,
                        detail_transfer: this.products
                    }
                    console.log(transfer)
                    await this.store(transfer);
                    if (this.transfer) {
                        this.clearData();
                        this.empty_transfer();
                        this.clean_data();
                    }
                }                
            }
        },
        handleInputSearchByEnter(KeyboardEvent){
            if (KeyboardEvent.code == 'Enter') {
                this.handleSearchInventory();
            }
        },
        async handleSearchInventory() {
            if (this.origin_id) {
                let filters = {
                    origin_id: this.origin_id,
                    category_id: this.value_category,
                    description: this.search_product,
                    value_stock: 'Con Stock'
                }
                await this.getProductsToTransfer(filters)
            } else {
                this.$swal({
                    text: "Debe seleccionar almacén de origen",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar"
                });
            }            
        },
        existErrors(){
            let hasErrors = false;

            if (!this.$refs.origin_id.value) hasErrors = true;
            this.$refs.origin_id.validate(true);

            if (!this.$refs.destination_id.value) hasErrors = true;
            this.$refs.destination_id.validate(true);  

            return hasErrors;
        },
        hasProducts(){
            let quantity = this.products.length;

            let has = true;
            if (this.origin_id == this.destination_id) {
                has = false;
                this.message_validation_error = 'Origen y Destino no pueden ser iguales';
                this.show_message_products = true;
                return has;
            }
            if (quantity <= 0) {
                has = false;
                this.message_validation_error = 'Debe ingresar los productos a transferir';
                this.show_message_products = true;
                return has;
            }

            return has;
        },
        clearErrors() {
            this.$refs.origin_id.reset();
            this.$refs.destination_id.reset();
        },
        existErrorsDialog(){
            let hasErrors = false;

            Object.keys(this.formSelect).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.formSelect[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrorsDialog() {
            Object.keys(this.formSelect).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        handleDeleteItem (index) {
            this.products.splice(index, 1);
        }
    },
    created () {
        this.setInventories([]);
        this.getStorehouses();
        this.getCategories();
        this.getOffices({
            company_id: this.company_id
        })
    },
    mounted() {      
        this.clearData()
        this.clean_data()        
    }
}
</script>

<style scoped>
.description-selected{
    text-transform: uppercase;
    font-size: .9rem !important;
    font-weight: 600;
}

.list-product-selected{
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: bold;
    font-size: 1rem;
    font-family: Roboto,sans-serif !important;
}

.section-list-products{
    height: 82vh;
}

.btn-item-delete{
    cursor: pointer;
}

.transfer-item-action{
    align-items: baseline !important;
    /*flex-direction: inherit !important;*/
    justify-content: start !important;
}

.label-quantity-transfer {
    font-size: 1rem !important;
}
</style>