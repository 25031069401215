<template>
    <div class="float-right">
        <span class="office_login">{{ office_login_name }} </span>
        /
        <span class="office_login" v-if="current_employee"> {{ current_employee.fullname }}</span>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'OfficeLogin',
    computed: {
        ...mapState('authentication', [
            'office_login_name',
            'current_employee'
        ])
    }
}
</script>